<template>
    <el-dialog v-model="dialogFormVisible" :title="title" @close="closeDialog">
        <el-form :model="addBaseDepartmentForm" ref="addBaseDepartmentRef" :rules="addBaseDepartmentrules">
                        <el-form-item label="部门名称" :label-width="formLabelWidth"
                                      prop="name">
                            <el-input
                                    v-model="addBaseDepartmentForm.name"
                                    autocomplete="off"
                                    ref="name"
                                    placeholder="请填写部门名称"
                            ></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="父部门id" :label-width="formLabelWidth"
                                      prop="parentId" v-if="addBaseDepartmentForm.id">
                            <el-input
                                    v-model="addBaseDepartmentForm.parentId"
                                    autocomplete="off"
                                    ref="name"
                                    placeholder="请填写父部门id"
                            ></el-input>
                        </el-form-item> -->
                        <el-form-item label="排序" :label-width="formLabelWidth"
                                      prop="sortNum">
                            <el-input
                                    v-model="addBaseDepartmentForm.sortNum"
                                    autocomplete="off"
                                    ref="name"
                                    placeholder="请填写排序"
                            ></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="部门类型" :label-width="formLabelWidth"
                                      prop="type" v-if="addBaseDepartmentForm.id">
                            <el-input
                                    v-model="addBaseDepartmentForm.type"
                                    autocomplete="off"
                                    ref="name"
                                    placeholder="请填写部门类型"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="是否可用" :label-width="formLabelWidth"
                                      prop="isEffect" v-if="addBaseDepartmentForm.id">
                            <el-input
                                    v-model="addBaseDepartmentForm.isEffect"
                                    autocomplete="off"
                                    ref="name"
                                    placeholder="请填写是否可用"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="单位id" :label-width="formLabelWidth"
                                      prop="campus" v-if="addBaseDepartmentForm.id">
                            <el-input
                                    v-model="addBaseDepartmentForm.campus"
                                    autocomplete="off"
                                    ref="name"
                                    placeholder="请填写单位id"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="单位名称" :label-width="formLabelWidth"
                                      prop="campusName">
                            <el-input
                                    v-model="addBaseDepartmentForm.campusName"
                                    autocomplete="off"
                                    ref="name"
                                    placeholder="请填写单位名称"
                            ></el-input>
                        </el-form-item> -->
        </el-form>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel()">取消</el-button>
        <el-button type="primary" @click="save()">保存</el-button>
      </span>
        </template>
    </el-dialog>
</template>

<script>
    import {ref} from "vue";
    import {httpService} from "@/utils/httpService";
    import {ElMessage, ElForm} from "element-plus";
    import {vxRule} from "@/utils/validator";

    export default {
        name: "AddBaseDepartment",
        components: {ElForm, ElMessage},
        data() {
            return {
                dialogFormVisible: ref(false),
                formLabelWidth: "120px",
                title: "",
                personnelType: "",
                addBaseDepartmentForm: {
                    name: "",
                    parentId: "",
                    sortNum: "",
                    type: "",
                    isEffect: "",
                    campus: "",
                    campusName: "",
                },
                // 校验规则
                addBaseDepartmentrules: {
                    name: vxRule(true, null, "blur"),
                    campusName: vxRule(true, null, "blur"),
                },
            };
        },
        methods: {
            closeDialog() {
                this.clear();
            },
            cancel() {
                this.dialogFormVisible = false;
                this.clear();
            },
            getBaseDepartmentById(id) {
                httpService("/api/baseDepartment/queryById", {id: id}, "get").then(
                    (data) => {
                        this.addBaseDepartmentForm = data;
                    }
                );
            },
            clear() {
                this.dialogFormVisible = false;
                //清空form
                    this.addBaseDepartmentForm.id = "";
                    this.addBaseDepartmentForm.name = "";
                    this.addBaseDepartmentForm.parentId = "";
                    this.addBaseDepartmentForm.sortNum = "";
                    this.addBaseDepartmentForm.type = "";
                    this.addBaseDepartmentForm.isEffect = "";
                    this.addBaseDepartmentForm.campus = "";
                    this.addBaseDepartmentForm.campusName = "";
            },
            save() {
                this.$refs.addBaseDepartmentRef.validate((valid) => {
                    if (valid) {
                        let params = new FormData(); //创建一个form对象,必须是form对象否则后端接受不到数据
                        params.append("name", this.addBaseDepartmentForm.name);
                        params.append("parentId", this.addBaseDepartmentForm.parentId);
                        params.append("sortNum", this.addBaseDepartmentForm.sortNum);
                        params.append("type", this.addBaseDepartmentForm.type);
                        params.append("isEffect", this.addBaseDepartmentForm.isEffect);
                        params.append("campus", this.addBaseDepartmentForm.campus);
                        params.append("campusName", this.addBaseDepartmentForm.campusName);
                        if (this.addBaseDepartmentForm.id) {
                            params.append("id", this.addBaseDepartmentForm.id);
                            httpService("/api/baseDepartment/edit", params, "post").then(
                                (data) => {
                                    if (data.code == 200) {
                                        ElMessage.success("修改成功");
                                        this.clear();
                                        //刷新父组件的数据
                                        this.$parent.getBaseDepartmentList();
                                    } else {
                                        ElMessage.error("修改失败");
                                    }
                                }
                            );
                        } else {
                            httpService("/api/baseDepartment/add", params, "post").then(
                                (data) => {
                                    if (data.code == 200) {
                                        ElMessage.success("添加成功");
                                        this.clear();
                                        //刷新父组件的数据
                                        this.$parent.getBaseDepartmentList();
                                    } else {
                                        ElMessage.error("添加失败");
                                    }
                                }
                            );
                        }
                    } else {
                        return false;
                    }
                });
            },
        },
    };
</script>
<style>
</style>